import styled from "styled-components";
import Background from "../common/Background";
import ContentBox from "../common/ContentBox";
import EmphasizedText from "../common/EmphasizedText";
import personalColor from "../../data/personalColorData.json";
import NextButton from "../common/NextButton";
import InfoBox from "../common/InfoBox";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import img from "../../assets/6_1_spring_warm_light_frame.svg";

const BackgroundFrame = () => {
  const location = useLocation();
  const idx = location.state?.idx || 0;
  const exColors = personalColor[idx].exColor;
  const img = personalColor[idx].frameImgSrc;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToOutro = () => {
    navigate("/outro");
  };
  return (
    <>
      <Background>
        <ContentBox>
          <FrameWrapper>
            <PaletteColors>
              <EmphasizedText
                color={personalColor[idx].personalColorCode}
                size="40px"
              >
                {t(personalColor[idx].personalColor)}
              </EmphasizedText>
            </PaletteColors>

            <InfoWrapper>
              <RecommendationFrame>
                <EmphasizedText size="20px">
                  {t("matchingFrame")}
                </EmphasizedText>
                <InfoBox>
                  <img src={img} alt="colorPalette" width="90%" />
                </InfoBox>
              </RecommendationFrame>
              <RecommendationColor>
                <EmphasizedText size="20px">
                  {t("matchingBackgroundColor")}
                </EmphasizedText>
                <InfoBox width="200px" height="200px" borderRadius="50%">
                  {exColors.map((color, index) => (
                    <ExColor key={index} color={color} />
                  ))}
                </InfoBox>
              </RecommendationColor>
            </InfoWrapper>
            <NextButton onClick={navigateToOutro}>{t("next")}</NextButton>
          </FrameWrapper>
        </ContentBox>
      </Background>
    </>
  );
};

const FrameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  line-height: 1.6;
`;

const PaletteColors = styled.div`
  flex: 1;
  height: 10%;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 90%;
  width: 100%;
  gap: 30px;
`;

const RecommendationFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const RecommendationColor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 0px -20px 0px 0px;
`;

const ExColor = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 5px; // 동그라미 간격
`;

export default BackgroundFrame;
