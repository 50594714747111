import "./App.css";
import { Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/translate/i18n"; // i18n 설정 파일의 경로
import Layout from "./components/layout/Layout";
import Intro from "./components/intro/Intro";
import Camera from "./components/camera/Camera";
import Loading from "./components/loading/Loading";
import PersonalColor from "./components/personal-color/PersonalColor";
import BackgroundFrame from "./components/background-frame/BackgroundFrame";
import Outro from "./components/outro/Outro";
import SaveImages from "./components/save-images/SaveImages";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/camera" element={<Camera />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/personal-color" element={<PersonalColor />} />
        <Route path="/background-frame" element={<BackgroundFrame />} />
        <Route path="/outro" element={<Outro />} />
        <Route path="/save-images" element={<SaveImages />} />
      </Routes>
    </I18nextProvider>
  );
};

export default App;
