import styled from "styled-components";

const InfoBox = styled.div`
  background-color: white;
  margin-top: 10px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.1),
    inset 3px 0px 5px rgba(0, 0, 0, 0.1);
  color: #5f9dd0;
  font-size: 13px;
  border-radius: ${(props) => props.borderRadius || "20px"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default InfoBox;
