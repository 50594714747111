import styled from "styled-components";

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  width: 90%;
  height: 85%;
  color: black;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
`;

export default ContentBox;
