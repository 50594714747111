import styled from "styled-components";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const UsageCircle = ({ textKey }) => {
  const { t } = useTranslation();
  return <Circle>{t(textKey)}</Circle>;
};

const UsageCircles = () => {
  return (
    <UsageContainer>
      <UsageCircle textKey="makeup" />
      <UsageCircle textKey="accessory" />
      <UsageCircle textKey="fashion" />
      <UsageCircle textKey="hair" />
    </UsageContainer>
  );
};

const UsageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
`;

const Circle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #7eb3df;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  margin: 10px;
  color: white;
  font-weight: bold;
  font-size: 14px;
`;

export default UsageCircles;
