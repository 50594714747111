// Intro.jsx
import styled from "styled-components";
import personalColorGraph from "../../assets/2_personal_color_graph.png";
import ColorTypeTable from "./ColorTypeTable";
import UsageCircles from "./UsageCircles";
import Background from "../common/Background";
import ContentBox from "../common/ContentBox";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EmphasizedText from "../common/EmphasizedText";
import NextButton from "../common/NextButton";

const Intro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCamera = () => {
    navigate("/camera");
  };

  return (
    <Background>
      <ContentBox>
        <Description>
          <EmphasizedText>{t("personalColorIntro")}</EmphasizedText>
          <br />
          <Trans i18nKey="personalColorDescription">
            <b>고유색</b>
            <b>장점을 돋보이게 해주는 색</b>
          </Trans>
        </Description>
        <ContentSplit>
          <PersonalColorImageWrapper>
            <PersonalColorImage
              src={personalColorGraph}
              alt="personalColorGraph"
            />
          </PersonalColorImageWrapper>

          <Information>
            <ColorTypeTable />
            <EmphasizedTextSm>{t("utilization")}</EmphasizedTextSm>
            <UsageCircles />
            <NextButton onClick={navigateToCamera}>{t("next")}</NextButton>
          </Information>
        </ContentSplit>
      </ContentBox>
    </Background>
  );
};

const Description = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  color: #595b5c;
  font-size: 16px;
`;

const EmphasizedTextSm = styled.span`
  margin-top: 20px;
  font-weight: bold;
  color: #fff;
  font-size: 24px;
  text-shadow: -2px -2px 0 #7eb3df, 2px -2px 0 #7eb3df, -2px 2px 0 #7eb3df,
    2px 2px 0 #7eb3df, -2px 0 #7eb3df, 2px 0 #7eb3df, 0 -2px #7eb3df,
    0 2px #7eb3df;
`;

const ContentSplit = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const PersonalColorImageWrapper = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PersonalColorImage = styled.img`
  width: 300px;
  height: 300px;
`;

const Information = styled.div`
  width: 50%;
  color: #595b5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Intro;
