import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import logo from "/1_the_film_logo.png";
import text from "/1_1_find_your_personal_color.png";
import "../translate/i18n";
import { useNavigate } from "react-router-dom";

const riseUpAndBounce = keyframes`
  0% {
    transform: translateY(20vh);
    opacity: 0;
  }
  30% {
    transform: translateY(-15%);
    opacity: 0.5;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
  }
  65% {
    transform: translateY(-5%);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  80% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }
  90% {
    transform: translateY(-1%);
    animation-timing-function: cubic-bezier(0.455, 0.030, 0.515, 0.955);
  }
  100% {
    transform: translateY(0);
    opacity: 1; // 여기에 opacity를 1로 설정
  }
`;

const holeShrink = keyframes`
  0% {
    transform: scale(1); // Start at full size
    opacity: 1; // Fully visible
  }
  100% {
    transform: scale(0); // Shrink down to nothing
    opacity: 0; // Fully transparent
  }
`;

const breatheAnimation = keyframes`
  0%, 100% {
    transform: scale(1); // 기본 크기
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000); // 빠르게 시작
  }
  50% {
    transform: scale(1.25); // 최대 25% 커짐
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060); // 튕기는 효과
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideMaskOff = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const Layout = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };

  const handleStartClick = () => {
    navigate("/intro");
  };

  return (
    <>
      <MainContainer>
        <Hole />
        <LogoContainer>
          <Logo src={logo} alt="logo" />
        </LogoContainer>
        <TextContainer>
          <AnimatedText src={text} alt="Find Your Personal Color" />
        </TextContainer>
        <ButtonContainer>
          <LanguageButtons>
            <LanguageButton onClick={() => handleLanguageChange("ko")}>
              한국어
            </LanguageButton>
            <LanguageButton onClick={() => handleLanguageChange("en")}>
              English
            </LanguageButton>
          </LanguageButtons>
          <StartButton onClick={handleStartClick}>{t("start")}</StartButton>
        </ButtonContainer>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #7eb3df;
  position: relative;
`;

const Logo = styled.img`
  max-width: 400px;
  position: relative;
`;

const LogoContainer = styled.div`
  position: relative;
  opacity: 0; // 시작할 때 로고 숨김
  z-index: 100;
  animation: ${riseUpAndBounce} 3s ease-out forwards,
    ${breatheAnimation} 2s ease-in-out infinite 3s; // riseUpAndBounce 후 breatheAnimation 시작
`;

const Hole = styled.div`
  position: absolute;
  bottom: 400px;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 300px; // 원하는 원의 크기에 맞게 조정
  height: 30px; // 원하는 원의 크기에 맞게 조정
  background-color: #79a0c0;
  border-radius: 50%;
  animation: ${holeShrink} 1.5s ease-in-out forwards;
`;

const LanguageButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const LanguageButton = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: 3px solid #e7e7e7;
  border-radius: 15px;
  background-color: transparent;
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const StartButton = styled.button`
  padding: 15px 40px;
  font-size: 1.5em;
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: #7eb3df;
  font-weight: bold;
  width: 220px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
  animation-delay: 3s;
`;

const TextContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  margin: 50px 0;
  z-index: 1;

  &::before {
    content: ""; // Correctly set the content to an empty string
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7eb3df; // Same as the MainContainer background color
    animation: ${slideMaskOff} 0.75s ease-out forwards;
    animation-delay: 3s;
  } // This closing bracket was missing
`;

const AnimatedText = styled.img`
  display: block;
  width: 100%;
`;

export default Layout;
