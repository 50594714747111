// i18n.js 파일
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const language = localStorage.getItem("i18nextLng") || "ko";

i18n.use(initReactI18next).init({
  lng: language,
  resources: {
    en: {
      translation: {
        start: "Start",
        selectKorean: "Korean selected",
        selectEnglish: "English selected",
        type: "Type",
        spring: "Spring",
        summer: "Summer",
        autumn: "Autumn",
        winter: "Winter",
        warm: "Warm",
        cool: "Cool",
        light: "Light",
        bright: "Bright",
        mute: "Mute",
        dark: "Dark",
        personalColorIntro: "What is Personal Color?",
        personalColorDescription:
          "It is the process of <0>finding colors</0> that harmonize with a <br/> one's original colors and <1>make them stand out</1>.",
        utilization: "utilization",
        next: "NEXT",
        makeup: "Makeup",
        accessory: "Accessory",
        fashion: "Fashion",
        hair: "Hair",
        colorPalette: "COLOR PALETTE",
        characteristic: "CHARACTERISTIC",
        avoid: "AVOID",
        matchingFrame: "MATCHING FRAME",
        matchingBackgroundColor: "MATCHING BACKGROUND COLOR",
        save: "SAVE",
        back: "BACK",
        countdownMessage:
          "Redirecting to the main screen in {{count}} seconds.",

        // 퍼스널 컬러
        springWarmLight: "SPRING WARM LIGHT",
        springWarmLightPalette:
          "**Yellow Base Color**\n with medium\n and low brightness\n -\n **Soft, Clear light,**\n **Fail-type** color",
        springWarmLightCharacteristic:
          "Goes well with a **Warm pastel**\n **tone** that's **Weak in contrast.**\n and has soft features,\n **Light skin** and **Hair tones.**",
        springWarmLightNo:
          "a **Cold** color with\n a **Vivid blue** color,\n **Too** bright or **Too** dark\n",
        springWarmLightEx: "EX. magenta, cobalt blue",

        springWarmBright: "SPRING WARM BRIGHT",
        springWarmBrightPalette:
          "**Clear** colors\n of medium\n and **High chroma**\n -\n Vivid, Bright\n type colors\n with **Yellow base**",
        springWarmBrightCharacteristic:
          "**Clear** features and **Good contrast**\n between skin and hair.\n Recommended **Clear, Warm** image\n glossy material, fancy pattern.",
        springWarmBrightNo: "**Blue** color mixed with **White**",
        springWarmBrightEx: "EX. Sky blue, light purple, mint",

        summerCoolLight: "SUMMER COOL LIGHT",
        summerCoolLightPalette:
          "**Bright** and **Clear**\n pastel-toned\n of high quality\n and low chroma\n -\n **Blue light**\n and **Pale** tone",
        summerCoolLightCharacteristic:
          "Goes well with **Cool pastel** colors.\n The skin and hair tone are **Bright**\n and digest **Proper contrast.**",
        summerCoolLightNo:
          "**Dark, yellowish brown** color\n mixed with **Black** or **Gray**",
        summerCoolLightEx: "EX. dark khaki, dark beige, etc.",

        summerCoolMute: "SUMMER COOL MUTE",
        summerCoolMutePalette:
          "**Pastel color**\n with toned down\n gray mixed with\n medium\n and **Low chroma**",
        summerCoolMuteCharacteristic:
          "Have **Soft** facial features and\n medium skin and hair tone.\n A type with a **Weak contrast** and\n a **Subtle and calm** color is the best.",
        summerCoolMuteNo:
          "Bright or dark color with\n a **Strong contrast,**\n a **Warm color** with a yellow tone",
        summerCoolMuteEx: "EX. Orange, Green, Purple, etc",

        autumnWarmMute: "AUTUMN WARM MUTE",
        autumnWarmMutePalette:
          "The bright color\n of **Gray light** \n of **Yellow base**\n -\n Soft, but  theige\n of low vegetables.",
        autumnWarmMuteCharacteristic:
          "Best with a **Light contrast,**\n calm and soft color.\n Patterns with a **Weak contrast**\n or matte materials look good.",
        autumnWarmMuteNo:
          "**Strong contrast** color.\n Mixture of blue and white\n **without Yellow**",
        autumnWarmMuteEx: "EX. magenta, hot pink, green",

        autumnWarmDark: "AUTUMN WARM DARK",
        autumnWarmDarkPalette:
          "**Yellow base**\n **Vivid color** with\n high reputation\n -\n Low saturationa\n mixture of\n red and black",
        autumnWarmDarkCharacteristic:
          "Have **Strong and**\n **Clear impression.** \n Looks good on \n **Colorful** or large patterns.",
        autumnWarmDarkNo:
          "Cold blue color with a weak\n contrast and a **Lack of Yellow.**\n Avoid **Pale** tones, **Turbid** colors,\n and **Primary** colors",
        autumnWarmDarkEx: "EX. White, light purple, sky blue",

        winterCoolBright: "WINTER COOL BRIGHT",
        winterCoolBrightPalette:
          "Bright, Vivid,\n Neon colors \n with **Medium**\n **and High chroma**\n -\n Cool primary color\n **Without Yellow**",
        winterCoolBrightCharacteristic:
          "**Less Yellow** on the skin,\n And **T-zone** is clear.\n **Contrasts well** with skin and hair.\n The glossy material and colorful\n pattern go well together.",
        winterCoolBrightNo:
          "**Grey murky** color, low chromatic\n mute, **Pastel** color",
        winterCoolBrightEx: "EX. Khaki, Camel, Orange, Brick",

        winterCoolDark: "WINTER COOL DARK",
        winterCoolDarkPalette:
          "**Contrasting** color\n of famous and \n low-colour\n -\n **Strong, dark**\n combination of\n black and \n blue dark colors",
        winterCoolDarkCharacteristic:
          "Goes well with the **Blue and Deep**\n colors that are **Heavy.**\n **Colorful** and large patterns and\n silver accessories go well together.",
        winterCoolDarkNo:
          "**White** pastel, pale color, and\n tone-on-tone with **Weak contrast**",
        winterCoolDarkEx: "EX. Olive, sky blue, lime, orange",
      },
    },
    ko: {
      translation: {
        start: "시작",
        selectKorean: "한국어 선택됨",
        selectEnglish: "영어 선택됨",
        type: "종류",
        spring: "봄",
        summer: "여름",
        autumn: "가을",
        winter: "겨울",
        warm: "웜",
        cool: "쿨",
        light: "라이트",
        bright: "브라이트",
        mute: "뮤트",
        dark: "다크",
        personalColorIntro: "퍼스널 컬러란?",
        personalColorDescription:
          "사람이 원래 지니고 있는 <0>고유색</0>과 조화를 이루며 <br/> 본인이 가진 <1>장점을 돋보이게 해주는 색</1>을 찾는 과정",
        utilization: "활용",
        next: "다음",
        makeup: "화장",
        accessory: "악세사리",
        fashion: "패션",
        hair: "헤어",
        colorPalette: "색상 팔레트",
        characteristic: "특징",
        avoid: "피할 색상",
        matchingFrame: "추천 프레임",
        matchingBackgroundColor: "추천 배경색",
        save: "저장",
        back: "이전",
        countdownMessage: "{{count}}초 후 메인 화면으로 넘어갑니다.",

        // 퍼스널 컬러
        springWarmLight: "봄 웜 라이트",
        springWarmLightPalette:
          "부드럽고 맑은\n **라이트, 페일** 타입\n -\n 고명도 중저채도의\n **옐로우 베이스**",
        springWarmLightCharacteristic:
          "대비감이 약하고 부드러운\n **따뜻한 파스텔 계열**이 잘 어울리는 타입\n **부드러운 이목구비**를 가졌으며\n 피부와 모발의 톤이 밝은 편이다.",
        springWarmLightNo:
          "**비비드, 푸른빛**의 차가운 색감의 한색\n **너무** 밝거나 **너무** 어두운 색상",
        springWarmLightEx: "EX. 마젠타, 코발트 블루, 청록색",

        springWarmBright: "봄 웜 브라이트",
        springWarmBrightPalette:
          "중명도 고채도의\n **선명**하고 **또렷**한 색감\n -\n **옐로우 베이스**의\n 비비드, 브라이트 타입",
        springWarmBrightCharacteristic:
          "**이목구비가 또렷**하며 피부와 모발의\n **대비감**이 잘 어울리는 타입.\n **선명하고 따뜻**한 이미지, 광택감이\n 있는 소재와 화려한 패턴 추천",
        springWarmBrightNo: "흰색이 섞인 푸른빛이 도는 느낌의 한색",
        springWarmBrightEx: "EX. 하늘색, 연보라, 민트색",

        summerCoolLight: "여름 쿨 라이트",
        summerCoolLightPalette:
          "고명도 저채도\n 밝고 맑은 **파스텔톤**\n -\n 푸른 계열의\n **라이트, 페일톤**",
        summerCoolLightCharacteristic:
          "시원한 색감의 **파스텔 계열**이\n 잘 어울리는 타입\n 피부와 모발의 톤이 밝으며\n **적당한 대비감**을 소화.",
        summerCoolLightNo:
          "검은색이나 회색이 섞인 어두운 색,\n **노란기가 도는** 브라운 계열",
        summerCoolLightEx: "EX. 진한 카키, 진한 베이지",

        summerCoolMute: "여름 쿨 뮤트",
        summerCoolMutePalette:
          "뮤트계열 탁한 색감\n -\n 중명도 중저채도의\n 회색이 섞인\n **톤 다운된 파스텔** 계열",
        summerCoolMuteCharacteristic:
          "**이목구비가 부드러우며**\n 피부와 모발 톤이 중간정도의 타입\n **대비감이 약하며** 은은하고\n 차분한 색감이 베스트인 타입",
        summerCoolMuteNo:
          "대비감이 강한 밝거나 어두운 색상,\n **노란기**가 도는 **따뜻한 느낌**의 색상",
        summerCoolMuteEx: "EX. 비비드 계열의 주황색, 초록색, 보라색",

        autumnWarmMute: "가을 웜 뮤트",
        autumnWarmMutePalette:
          "옐로우 베이스의\n **회색 빛 밝은 컬러**\n -\n 부드럽지만\n 진하지 않은\n **저채도의 베이지** 계열",
        autumnWarmMuteCharacteristic:
          "**대비감이 약한** 배색, 차분하면서도\n 부드러운 컬러가 베스트인 타입\n 반짝임이 강하지 않은 매트한 소재,\n **대비감이 약한** 패턴이 잘 어울린다.",
        autumnWarmMuteNo:
          "대비감이 강한 밝거나 어두운 색상\n 노란기가 빠진 **푸른 빛의 차가운 색감**,\n 흰색이 섞인 색상",
        autumnWarmMuteEx: "EX. 마젠타, 핫핑크, 초록색, 주황색",

        autumnWarmDark: "가을 웜 다크",
        autumnWarmDarkPalette:
          "저명도 저채도의\n **옐로우 베이스**의\n 선명한 색상\n -\n 레드와 블랙이 섞인\n **어둡고 진한** 컬러",
        autumnWarmDarkCharacteristic:
          "**진하고 선명한 인상**을 가지고 있는 편\n 대비감이 강한 스타일이며\n 화려하거나 큰 패턴도 잘 어울린다.",
        autumnWarmDarkNo:
          "대비감이 약하고 노란기가 빠진\n 푸른빛의 차가운 색상\n **페일톤이나 탁색, 원색**",
        autumnWarmDarkEx: "EX. 화이트, 연보라, 하늘색, 연핑크",

        winterCoolBright: "겨울 쿨 브라이트",
        winterCoolBrightPalette:
          "중명도 고채도의\n **브라이트,**\n **비비드, 네온** 컬러\n -\n 노란기가 없는\n **시원한** 느낌의 **원색**",
        winterCoolBrightCharacteristic:
          "노란기가 적고 **이목구비가 뚜렷한** 편\n 피부와 헤어 등 **대비감을 잘 받는다.**\n 광택이 강한 소재와\n 화려한 패턴이 잘 어울린다.",
        winterCoolBrightNo:
          "회색 빛의 탁한 색감, 저채도의\n **뮤트, 파스텔 계열** 색상",
        winterCoolBrightEx: "EX. 카키, 오렌지, 브라운",

        winterCoolDark: "겨울 쿨 다크",
        winterCoolDarkPalette:
          "저명도 저채도의\n **대비감이 강한** 배색\n -\n 블랙과 블루가\n 섞인 다크, 딥 타입의\n **강하고 진한** 색상",
        winterCoolDarkCharacteristic:
          "무게감이 있는 **푸르고 깊은 색감**이 \n잘 어울리는 타입이다. \n화려하면서 큰 패턴, **실버계열**의\n 악세사리가 잘 어울린다.",
        winterCoolDarkNo:
          "**흰기가 도는** 파스텔, 페일 계열의 색상,\n 대비감이 약한 **톤온톤 코디**",
        winterCoolDarkEx: "EX. 라이트 핑크, 라이트 그린, 옐로우",
      },
    },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // React에서는 기본적으로 XSS 방지를 하므로 false 설정
  },
});

export default i18n;
