import styled from "styled-components";

const EmphasizedText = styled.span`
  font-weight: bold;
  color: ${(props) => props.color || "#5f9dd0"};
  font-size: ${(props) => props.size || "32px"};
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    2px 2px 0 #fff;
  font-family: "Pretendard-Bold";
`;

export default EmphasizedText;
