import styled from "styled-components";
import outropage from "../../assets/7_outro_page.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { ImCross } from "react-icons/im";

const Outro = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasInteracted, setHasInteracted] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [showQRCode, setShowQRCode] = useState(false);
  const [encodedData, setEncodedData] = useState("");

  useEffect(() => {
    const baseUrl =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://thefilm-personal-color.netlify.app";

    const personalColorIdx =
      parseInt(localStorage.getItem("personalColorIdx"), 10) || 0;
    const savingPageUrl = `${baseUrl}/save-images?idx=${personalColorIdx}`;
    setEncodedData(savingPageUrl);

    const data = localStorage.getItem("dataToEncode");
    if (data) {
      setEncodedData(data);
    }

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const timeoutId = setTimeout(() => {
      if (!hasInteracted) {
        navigate("/");
        localStorage.removeItem("personalColorIdx");
      }
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [navigate, hasInteracted]);

  const goToFramePage = () => {
    localStorage.removeItem("personalColorIdx");
    navigate("/");
  };

  const handleCloseModal = () => {
    setShowQRCode(false);
    setHasInteracted(false);
    setCountdown(10);
  };

  const handleSaveClick = () => {
    const personalColorIdx =
      parseInt(localStorage.getItem("personalColorIdx"), 10) || 0;

    const savingPageUrl = `https://yourwebsite.com/save-images?idx=${personalColorIdx}`;
    setEncodedData(savingPageUrl);

    setHasInteracted(true);
    setShowQRCode(true);
  };

  return (
    <ImageContainer>
      <ResponsiveImage src={outropage} alt="Outro Image" />
      {countdown > 0 && !hasInteracted && (
        <CountdownText>
          {t("countdownMessage", { count: countdown })}
        </CountdownText>
      )}
      <SaveButton onClick={handleSaveClick}>{t("save")}</SaveButton>
      {showQRCode && (
        <Modal>
          <QRCodeWrapper>
            <QRCode value={encodedData} size={256} />
            <CloseButton onClick={handleCloseModal}>
              <ImCross />
            </CloseButton>
          </QRCodeWrapper>
        </Modal>
      )}
      <PreviousButton onClick={goToFramePage}>{t("back")}</PreviousButton>
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100vh;
`;

const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;

const SaveButton = styled.button`
  width: 90px;
  position: absolute;
  bottom: 155px;
  right: 350px;
  background-color: white;
  color: #7eb3df;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  font-family: "Pretendard-Black";
`;

const PreviousButton = styled.button`
  width: 90px;
  position: absolute;
  bottom: 155px;
  left: 350px;
  background-color: white;
  color: #7eb3df;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  font-family: "Pretendard-Black";
`;

const CountdownText = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: #000;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const QRCodeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const CloseButton = styled.button`
  background-color: #8f8f8f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  cursor: pointer;
  z-index: 101;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Outro;
