import Background from "../common/Background";
import ContentBox from "../common/ContentBox";
import styled from "styled-components";
import personalColor from "../../data/personalColorData.json";
import EmphasizedText from "../common/EmphasizedText";
import NextButton from "../common/NextButton";
import InfoBox from "../common/InfoBox";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ColorGrid from "./ColorGrid";
import { useState, useEffect } from "react";

const PersonalColor = () => {
  const renderTextWithEmphasisAndNewlines = (text) => {
    return text
      .split("\n")
      .map((paragraph, index) => (
        <div key={`paragraph-${index}`}>
          {paragraph
            .split(/\*\*(.+?)\*\*/)
            .map((part, partIndex) =>
              partIndex % 2 === 1 ? (
                <FontBold key={`part-${partIndex}`}>{part}</FontBold>
              ) : (
                part
              )
            )}
        </div>
      ));
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getLocalStorageIdx = () => {
    const storedIdx = localStorage.getItem("personalColorIdx");
    return storedIdx ? parseInt(storedIdx, 10) : 0;
  };

  const [idx, setIdx] = useState(() => {
    const localIdx = getLocalStorageIdx();
    return location.state?.idx ?? localIdx;
  });

  useEffect(() => {
    if (location.state?.idx !== undefined) {
      localStorage.setItem("personalColorIdx", location.state.idx.toString());
      setIdx(location.state.idx);
    }
  }, [location.state]);

  const navigateToFrameInfo = () => {
    navigate("/background-frame", { state: { idx } });
  };

  return (
    <Background>
      <ContentBox>
        <PersonalColorWrapper>
          <PaletteColors>
            <EmphasizedText
              color={personalColor[idx].personalColorCode}
              size="40px"
            >
              {t(personalColor[idx].personalColor)}
            </EmphasizedText>
          </PaletteColors>

          <InfoWrapper>
            <Palette>
              <PaletteDescription>
                <EmphasizedText size="20px">{t("colorPalette")}</EmphasizedText>
                <LeftContent>
                  <CustomInfoBox
                    width="220px"
                    height="220px"
                    borderRadius="50%"
                    position="relative"
                    left="25px"
                    zIndex="5"
                  >
                    <ColorGrid idx={idx} />
                  </CustomInfoBox>
                  <CustomInfoBox
                    width="180px"
                    height="180px"
                    borderRadius="50%"
                    position="relative"
                    right="25px"
                  >
                    <ColorPaletteDesc>
                      {renderTextWithEmphasisAndNewlines(
                        t(personalColor[idx].colorPalette)
                      )}
                    </ColorPaletteDesc>
                  </CustomInfoBox>
                </LeftContent>
              </PaletteDescription>
            </Palette>
            <Info>
              <Characteristics>
                <EmphasizedText size="20px">
                  {t("characteristic")}
                </EmphasizedText>
                <InfoBox>
                  {renderTextWithEmphasisAndNewlines(
                    t(personalColor[idx].characteristic)
                  )}
                </InfoBox>
              </Characteristics>
              <NoColor>
                <EmphasizedText size="20px">{t("avoid")}</EmphasizedText>
                <InfoBox>
                  {renderTextWithEmphasisAndNewlines(t(personalColor[idx].no))}
                  <Example>{t(personalColor[idx].ex)}</Example>
                </InfoBox>
              </NoColor>
            </Info>
            <NextButton onClick={navigateToFrameInfo}>{t("next")}</NextButton>
          </InfoWrapper>
        </PersonalColorWrapper>
      </ContentBox>
    </Background>
  );
};

const FontBold = styled.span`
  font-family: "Pretendard-Black";
`;

const PersonalColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  line-height: 1.6;
`;

const Palette = styled.div`
  display: flex;
  align-items: flex-start;
  width: 60%;
`;

const PaletteColors = styled.div`
  flex: 1;
  height: 10%;
`;

const PaletteDescription = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  height: 90%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 40%;
  margin: 0px -20px 0px 20px;
`;

const Example = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Characteristics = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const NoColor = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CustomInfoBox = styled(InfoBox)`
  position: ${(props) => props.position || "static"};
  top: ${(props) => props.top || "auto"};
  right: ${(props) => props.right || "auto"};
  bottom: ${(props) => props.bottom || "auto"};
  left: ${(props) => props.left || "auto"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  border-radius: ${(props) => props.borderRadius || "0"};
  z-index: ${(props) => props.zIndex || "1"};
`;

const ColorPaletteDesc = styled.div`
  position: relative;
  z-index: 10;
`;

export default PersonalColor;
