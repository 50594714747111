import styled from "styled-components";

const NextButton = styled.button`
  padding: 10px 20px;
  background-color: #fff;
  color: #7eb3df;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  align-self: flex-end;
  font-size: 20px;
  width: 100px;
  text-shadow: -0.5px -0.5px 0 #7eb3df, 0.5px -0.5px 0 #7eb3df,
    -0.5px 0.5px 0 #7eb3df, 0.5px 0.5px 0 #7eb3df;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export default NextButton;
