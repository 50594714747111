import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const Loading = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 5초에서 10초 사이의 랜덤 시간을 생성
    const loadingTime = 15000;

    // 설정된 시간 후에 '/personal-color' 경로로 이동
    const timer = setTimeout(() => {
      navigate("/personal-color");
    }, loadingTime);

    // 컴포넌트가 언마운트 될 때 타이머를 클리어
    return () => clearTimeout(timer);
  }, [navigate]); // navigate를 의존성 배열에 추가

  return (
    <LoadingContainer>
      <LoadingText>Loading...</LoadingText>
    </LoadingContainer>
  );
};

const fillAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const LoadingText = styled.div`
  color: grey;
  font-size: 3em;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  font-family: "Pretendard-Bold";

  &::after {
    content: "Loading...";
    color: skyblue;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    animation: ${fillAnimation} 1.5s linear infinite;
  }
`;

// LoadingContainer 컴포넌트에 적용할 스타일
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
`;
export default Loading;
