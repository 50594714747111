/* eslint-disable no-unused-vars */
import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import cameraButton from "../../assets/3_camera_button.png";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import axios from "axios";

const Camera = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);

  const uploadS3 = (blob) => {
    const REGION = import.meta.env.VITE_AWS_REGION;
    const BUCKET_NAME = import.meta.env.VITE_AWS_BUCKET_NAME;
    const ACESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY;
    const SECRET_ACESS_KEY_ID = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY;

    AWS.config.update({
      region: REGION,
      accessKeyId: ACESS_KEY_ID,
      secretAccessKey: SECRET_ACESS_KEY_ID,
    });

    const imageFileName = `upload/${Date.now()}.jpg`;

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: BUCKET_NAME,
        Key: imageFileName,
        Body: blob,
        ContentType: "image/jpg",
        ACL: "public-read",
      },
    });

    return upload.promise();
  };

  // 사용자의 카메라에 접근하여 스트림을 가져옴
  useEffect(() => {
    const enableStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error(err);
      }
    };

    enableStream();
    // 컴포넌트가 언마운트될 때 스트림을 종료함
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  // 사진 촬영
  const captureImage = () => {
    if (videoRef.current) {
      let canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      // 캔버스에서 이미지를 blob으로 변환
      // Inside captureImage function
      canvas.toBlob(async (blob) => {
        const imageFile = new File([blob], `image_${Date.now()}.jpg`, {
          type: "image/jpg",
        });
        navigate("/loading");
        await handleImageUploadAndAnalysis(imageFile);
      }, "image/jpg");
    }
  };

  const handleImageUploadAndAnalysis = async (imageFile) => {
    try {
      // uploadS3 함수의 결과를 uploadResponse 변수에 저장
      const uploadResponse = await uploadS3(imageFile);

      if (!uploadResponse || !uploadResponse.Location) {
        throw new Error("Failed to upload image to S3");
      }

      const imageUrl = uploadResponse.Location;

      const analysisResponse = await axios.post(
        "https://theflim.shop/face/analyze",
        {
          url: imageUrl,
        }
      );

      const resultIndex = analysisResponse.data;

      navigate("/personal-color", { state: { idx: resultIndex } });
    } catch (error) {
      console.error("Error during image upload or analysis:", error);
    }
  };

  return (
    <CameraWrapper>
      <CameraContainer>
        <CameraBorderWrapper>
          <CameraBorder />
          <CaptureButton
            src={cameraButton}
            alt="Capture"
            onClick={captureImage}
            onTouchEnd={captureImage}
          />
        </CameraBorderWrapper>
        <VideoWrapper>
          <VideoFeed autoPlay playsInline ref={videoRef} />
        </VideoWrapper>
      </CameraContainer>
    </CameraWrapper>
  );
};

const CameraWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: white;
`;

const CameraContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const CameraBorderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const CaptureButton = styled.img`
  margin-top: 20px;
  height: 50px;
  cursor: pointer;
`;

const CameraBorder = styled.div`
  height: 580px;
  width: 1100px;
`;

const VideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

const VideoFeed = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default Camera;
