import styled from "styled-components";
import { useLocation } from "react-router-dom";
import result01k from "../../assets/result01_korean.png";
import result02k from "../../assets/result02_korean.png";
import result11k from "../../assets/result11_korean.png";
import result12k from "../../assets/result12_korean.png";
import result21k from "../../assets/result21_korean.png";
import result22k from "../../assets/result22_korean.png";
import result31k from "../../assets/result31_korean.png";
import result32k from "../../assets/result32_korean.png";
import result41k from "../../assets/result41_korean.png";
import result42k from "../../assets/result42_korean.png";
import result51k from "../../assets/result51_korean.png";
import result52k from "../../assets/result52_korean.png";
import result61k from "../../assets/result61_korean.png";
import result62k from "../../assets/result62_korean.png";
import result71k from "../../assets/result71_korean.png";
import result72k from "../../assets/result72_korean.png";
import result01e from "../../assets/result01_english.png";
import result02e from "../../assets/result02_english.png";
import result11e from "../../assets/result11_english.png";
import result12e from "../../assets/result12_english.png";
import result21e from "../../assets/result21_english.png";
import result22e from "../../assets/result22_english.png";
import result31e from "../../assets/result31_english.png";
import result32e from "../../assets/result32_english.png";
import result41e from "../../assets/result41_english.png";
import result42e from "../../assets/result42_english.png";
import result51e from "../../assets/result51_english.png";
import result52e from "../../assets/result52_english.png";
import result61e from "../../assets/result61_english.png";
import result62e from "../../assets/result62_english.png";
import result71e from "../../assets/result71_english.png";
import result72e from "../../assets/result72_english.png";

const SaveImages = () => {
  const location = useLocation();

  const getIdxFromQuery = () => {
    const searchParams = new URLSearchParams(location.search);
    return parseInt(searchParams.get("idx")) || 0;
  };

  const idx = getIdxFromQuery();

  const imagePairsKorean = [
    [result01k, result02k],
    [result11k, result12k],
    [result21k, result22k],
    [result31k, result32k],
    [result41k, result42k],
    [result51k, result52k],
    [result61k, result62k],
    [result71k, result72k],
  ];

  const imagePairsEnglish = [
    [result01e, result02e],
    [result11e, result12e],
    [result21e, result22e],
    [result31e, result32e],
    [result41e, result42e],
    [result51e, result52e],
    [result61e, result62e],
    [result71e, result72e],
  ];

  return (
    <div>
      <InstructionText>
        모바일 환경이라면 이미지를 꾹 눌러서 사진을 저장할 수 있습니다.
        <br />
        If you are on a mobile device, press and hold the image to save it.
      </InstructionText>
      <div>
        {imagePairsKorean[idx].map((imageUrl, index) => (
          <div key={`korean-${index}`}>
            <img
              src={imageUrl}
              alt={`Korean Result ${index + 1}`}
              style={{ maxWidth: "100%" }}
            />
          </div>
        ))}
      </div>

      <div>
        {imagePairsEnglish[idx].map((imageUrl, index) => (
          <div key={`english-${index}`}>
            <img
              src={imageUrl}
              alt={`English Result ${index + 1}`}
              style={{ maxWidth: "100%" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const InstructionText = styled.p`
  text-align: center;
  margin-top: 20px;
  font-size: 24px;
  color: white;
`;

export default SaveImages;
