import styled from "styled-components";
import personalColor from "../../data/personalColorData.json";

// eslint-disable-next-line react/prop-types
const ColorGrid = ({ idx }) => {
  const colors = personalColor[idx].gridColor;

  return (
    <GridContainer>
      {colors.map((color, index) => (
        <ColorCircle key={index} color={color} />
      ))}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const ColorCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export default ColorGrid;
