import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ColorTypeTable = () => {
  const { t } = useTranslation(); // 다국어 번역을 위한 훅 사용

  return (
    <TableWrapper>
      <TableInnerWrapper>
        <Table>
          <tbody>
            <Row>
              <HeaderCellEmphasizedText>{t("type")}</HeaderCellEmphasizedText>
              <HeaderCell>{t("spring")}</HeaderCell>
              <HeaderCell>{t("summer")}</HeaderCell>
              <HeaderCell>{t("autumn")}</HeaderCell>
              <HeaderCell>{t("winter")}</HeaderCell>
            </Row>
            <Row>
              <HeaderCell>{t("warm")}</HeaderCell>
              <TypeCell>{t("light")}</TypeCell>
              <TypeCell>{t("bright")}</TypeCell>
              <TypeCell>{t("mute")}</TypeCell>
              <TypeCell>{t("dark")}</TypeCell>
            </Row>
            <Row>
              <HeaderCell>{t("cool")}</HeaderCell>
              <TypeCell>{t("light")}</TypeCell>
              <TypeCell>{t("bright")}</TypeCell>
              <TypeCell>{t("mute")}</TypeCell>
              <TypeCell>{t("dark")}</TypeCell>
            </Row>
          </tbody>
        </Table>
      </TableInnerWrapper>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
`;

const TableInnerWrapper = styled.div`
  padding: 20px;
  border-radius: 25px;
  box-shadow: inset 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #b9ddfb;
`;

const Table = styled.table`
  width: 100%;
  margin: auto;
  height: 120px;
  border-collapse: collapse;
  background-color: #b9ddfb;
  color: #5696cb;
  font-weight: bold;
  font-size: 16px;
  border-radius: 25px;
`;

const Row = styled.tr`
  &:first-child th {
    border-bottom: 2px solid #5696cb;
  }
`;

const HeaderCell = styled.th`
  text-align: center;
  padding: 8px;
  border: none;
  text-shadow: -0.5px -0.5px 0 #5696cb, 0.5px -0.5px 0 #5696cb,
    -0.5px 0.5px 0 #5696cb, 0.5px 0.5px 0 #5696cb;
  &:first-child {
    border-right: 2px solid #5696cb;
  }
  &:first-of-type {
    border-right: 2px solid #5696cb;
  }
`;

const HeaderCellEmphasizedText = styled(HeaderCell)`
  font-weight: bold;
  color: #7eb3df;
  text-align: center;
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    2px 2px 0 #fff;
  font-size: 1.2em;
`;

const TypeCell = styled.td`
  text-align: center;
  padding: 8px;
  border: none;
`;

export default ColorTypeTable;
